.TextButton {
    background-color: transparent;
    border: none;
    color: #616161;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 15px; 
    padding: 0; 
    text-decoration: underline; 
}
