.Logo {
    padding: 10px; 
    height: 75%;
    display: flex; 
    justify-content: center; 
    align-items: center;
    text-decoration: none;
    user-select: none;
    color: black;
}

.Logo img {
    max-height: 45px; 
}

.Text {
    margin-left: 8px; 
    display: flex; 
}

.Name {
    font-size: 23px; 
    letter-spacing: 0.23px; 
    font-weight: 800; 
}

.Version {
    color: #616161; 
    font-size: 8px; 
}
