.Title {
    text-align: center;
    font-size: 22.4px;
    font-weight: bold;  
    margin-bottom: 20px;
}

.Error {
    text-align: center; 
    color: red; 
    margin-top: -20px; 
    margin-bottom: 1px;
}
