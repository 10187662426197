.LoadingSpinner {
    font-size: 10px;
    margin: 60px auto;
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border-top: 1.1em solid rgba(52, 17, 51, 0.2);
    border-right: 1.1em solid rgba(52, 17, 51, 0.2);
    border-bottom: 1.1em solid rgba(52, 17, 51, 0.2);
    border-left: 1.1em solid #616161; 
    transform: translateZ(0);
    animation: loading 1.1s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
