.Stats {
    display: flex; 
    flex-direction: column; 
    color: #616161;
    padding: 16px 16px 16px 16px; 
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 2px 2px 2px #ccc;
    border-radius: 0 10px 0 10px; 
    flex: 1 0 auto; 
    margin-right: 16px; 
}

.StatsHeader {
    font-size: 20px; 
    color: black; 
    font-weight: bold;
    text-decoration: underline; 
    margin-bottom: 16px; 
}

.Separator {
    width: 100%; 
    color: #616161;
    margin: 12px auto 12px auto; 
}
