.Display {
    padding: 16px 16px 16px 16px; 
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 2px 2px 2px #ccc;
    border-radius: 10px 0 10px 0; 
    display: flex; 
    flex-direction: column;
}

.Separator {
    width: 100%; 
    color: #616161; 
    margin: 16px 0 0 0; 
}
