.Modal {
    position: fixed;
    z-index: 150;
    background-color: white;
    width: 70%;
    padding: 20px;
    left: 15%;
    top: 50%; 
    transform: translateY(-50%); 
    border-radius: 10px;
    box-sizing: border-box; 
}

@media (min-width: 600px) {
    .Modal {
        width: 450px;
        left: calc(50% - 225px);
    }
}

.OpenPrize {
    animation: openPrize 0.2s; 
}

@keyframes openPrize {
    0% {
        opacity: 0;
        transform: scale(2); 
    }
    50% {opacity: 0.5;}
    100% {opacity: 1;}
}
