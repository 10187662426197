.Input {
    width: 100%; 
    margin-bottom: 20px; 
}

.InputElement {
    outline: none; 
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px; 
    display: block;
    width: 100%; 
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc; 
}

.Invalid {
    border: 1px solid red;
    background-color: rgb(242, 165, 156); 
}

.ValidationError {
    text-align: left;
    color: red;
    margin-top: 1px;
    margin-bottom: -10px;  
}
