.Rarity { 
    display: flex; 
    align-items: center;
    justify-content: space-between;
}

.RarityText {
    line-height: 5px; 
}

.RaritySquare {
    width: 20px;
    height: 20px; 
}

.Rainbow {
    animation: rainbow 0.5s linear infinite; 
}

@keyframes rainbow {
    0%, 100% {background: green;}
    20% {background: cyan;}
    40% {background: yellow;}
    60% {background: red;}
    80% {background: blue;}
}
