.Prize {
    display: flex; 
}

.Info {
    width: 100%; 
    text-align: center; 
    padding-right: 16px; 
}

.Stats {
    position: absolute;
    bottom: 20px; 
}

.Stat {
    text-align: left;
    font-size: 12.8px; 
}

.Description {
    text-align: left; 
    font-size: 14.4px; 
    font-style: italic; 
    color: #616161; 
}

.Item {
    flex-shrink: 0; 
    flex-grow: 0;
}

.Rainbow {
    animation: rainbow 0.5s linear infinite; 
}

@keyframes rainbow {
    0%, 100% {color: green;}
    20% {color: cyan;}
    40% {color: yellow;}
    60% {color: red;}
    80% {color: blue;}
}
