@media (max-width: 1500px), (max-height: 1050px) {
    .Spin {
        transform: scale(0.7);
    }
}

.Right {
    position: absolute;
    top: 200px; 
    left: 50%; 
    margin-left: 400px; 
    height: 700px; 
    width: 200px; 
    display: flex; 
    flex-direction: column;
}

@media (max-width: 1500px), (max-height: 1050px) {
    .Right {
        top: 100px; 
    }
}
