.Item {
    height: 200px;
    width: 200px;
    border-radius: 10px; 
    border: 5px solid #616161;  
    background-color: white;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    animation: spin linear 0.1s;
    user-select: none;
}

.ItemRainbow {
    animation: spin linear 0.1s, rainbowBorder 0.5s linear infinite;
}

.ItemNameRainbow {
    animation: spin linear 0.1s, rainbow 0.5s linear infinite;
}

.Item:hover {
    cursor: pointer; 
    border: dashed; 
    transition: 0.01s; 
}

.DisableHover {
    pointer-events: none;
}

.DisableSpin {
    animation: none; 
}

.DisableSpinRainbow {
    animation: rainbowBorder 0.5s linear infinite; 
}

.DisableSpinRainbowName {
    animation: rainbow 0.5s linear infinite; 
}

.ItemShowcase {
    height: 185px;
    width: 185px;
    margin: 0 16px 0 0; 
}

.ItemImage {
    padding: 5px; 
    text-align: center; 
    color: #616161; 
    object-fit: contain;
    height: 60%;
    width: 60%; 
    margin: auto;
}

.ItemNullText {
    padding: 5px; 
    text-align: center; 
    color: #616161; 
}

.ItemName {
    font-size: 20px; 
    padding: 5px; 
    text-align: center; 
    font-weight: bold;
}

.ItemBottomText {
    padding: 5px; 
    text-align: right; 
    color: #616161;
    line-height: 16px; 
}

@keyframes rainbowBorder {
    0%, 100% {border-color: green;}
    20% {border-color: cyan;}
    40% {border-color: yellow;}
    60% {border-color: red;}
    80% {border-color: blue;}
}

@keyframes rainbow {
    0%, 100% {color: green;}
    20% {color: cyan;}
    40% {color: yellow;}
    60% {color: red;}
    80% {color: blue;}
}

@keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-360deg);
    }
}
