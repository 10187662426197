.NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.NavigationItem a {
    color: #616161;
    height: 100%;
    padding: 12px 16px;
    text-decoration: none; 
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: #616161;
    color: white;
    transition: 0.25s; 
}
 