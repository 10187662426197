.Market {
    display: grid; 
    grid-gap: 20px 20px;
    padding: 45px; 
    grid-template-columns: repeat(auto-fit, 210px); 
    justify-items: center; 
    justify-content: center;
}

.FetchError {
    text-align: center; 
    font-weight: bold; 
    color: #616161; 
    margin-top: 100px; 
    animation: sizeChange 1s ease-in-out infinite; 
}

.LoadingSpinner {
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%); 
}

.MarketNullText {
    margin-top: 100px; 
    text-align: center;
    color: #616161; 
}

@keyframes sizeChange {
    0% {font-size: 40px}
    50% {font-size: 42px}
    100% {font-size: 40px}
}
