.ProfileCard {
    padding: 10px; 
    height: 75%;
    display: flex; 
    text-decoration: none;
    user-select: none;
    color: black;
}

.ProfileCard img {
    max-height: 45px; 
}

.Text {
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    margin-left: 8px; 
}

.User {
    font-size: 20.8px; 
    font-weight: 800; 
    line-height: 20.8px;
}

.SP {
    color: #616161; 
}
