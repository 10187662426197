.Spinner {
    position: absolute; 
    top: 200px; 
    left: 50%; 
    margin-left: -350px; 
}

@media (max-width: 1500px), (max-height: 1050px) {
    .Spinner {
        top: 100px;
    }
}

.SpinnerButton {
    position: absolute; 
    top: 100px; 
    left: 100px;
    margin: 0; 
    height: 500px;
    width: 500px;
    padding: 0; 
    background-color: #e33d3d; 
    border: none; 
    border-radius: 50%;
    box-shadow: 0 0 20px black; 
    transform-style: preserve-3d;
}

.SpinnerButton:hover,
.SpinnerButton:active {
    background-color: #e14f4f; 
    cursor: pointer; 
    outline: 0; 
}

.SpinnerButton:disabled {
    background-color: #e33d3d; 
    cursor: not-allowed; 
}

.Pointer {
    position: absolute; 
    top: -29.5px;
    left: 230px; 
    border-color: transparent transparent black transparent;
    border-style: solid;
    border-width: 0px 20px 30px 20px;
    height: 0px;
    width: 0px;
    transform: translateZ(-10px);
}

.RarityCircle {
    position: absolute;  
    height: 700px;
    width: 700px;
    padding: 0; 
    margin: 0;  
    background-color: #ccc; 
    border: none; 
    border-radius: 50%;
    z-index: -1;  
    transform: translateZ(-11px);
    list-style: none;
    overflow: hidden; 
}

.liCustom {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0% 100%;
}

.liCustom:first-child {
    transform: rotate(0deg); /* Common */
    background: brown;   
} 
.liCustom:nth-child(2) {
    transform: rotate(90deg); /* Common */
    background: brown; 
}
.liCustom:nth-child(3) {
    transform: rotate(179deg); /* Common 180 degrees (179 to cover up)*/
    background: brown;  
}
.liCustom:nth-child(4) {
    transform: rotate(187.2deg); /* Uncommon */
    background: cyan; 
}
.liCustom:nth-child(5) {
    transform: rotate(259.2deg); /* Rare */
    background: red;  
}
.liCustom:nth-child(6) {
    transform: rotate(313.2deg); /* Epic */
    background: purple;
}
.liCustom:nth-child(7) {
    transform: rotate(349.2deg); /* Holy */ 
    background: orange;
}
.liCustom:nth-child(8) {
    transform: rotate(358.2deg); /* Godly */ 
    background: yellow; 
}

.liCustom:nth-child(9) {
    transform: rotate(359.964deg); /* ??? */
    animation: rainbow 0.5s linear infinite; 
}  

.liCustom:nth-child(10) {
    transform: rotate(360deg); /* Common */
    background: brown; 
}

.liCustom:nth-child(11) {
    transform: rotate(361deg); /* to cover up */
    background: brown; 
}

@keyframes rainbow {
    0%, 100% {background: green;}
    20% {background: cyan;}
    40% {background: yellow;}
    60% {background: red;}
    80% {background: blue;}
}

.SpinnerText1 {
    font-size: 90px; 
    font-weight: bold;
    color: white;  
}

.SpinnerText2 {
    font-size: 30px; 
    color: #dedcdc; 
}

.SpinnerText3 {
    color: white; 
    font-weight: bold; 
    animation: sizeChange 1s ease-in-out infinite; 
}

.SpinnerText4 {
    color: white; 
    font-size: 45px; 
    font-weight: bold; 
}

@keyframes sizeChange {
    0% {font-size: 45px}
    50% {font-size: 50px}
    100% {font-size: 45px}
}
