.Toolbar {
    position: fixed; 
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white; 
    z-index: 75; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    box-shadow: 0px 3px #ccc; 
}
