.SP {
    position: absolute; 
    top: 950px; 
    text-align: center; 
    font-size: 25px;
    width: 100%; 
    display: flex; 
    justify-content: center;
    align-items: center; 
}

@media (max-width: 1500px), (max-height: 1050px) {
    .SP {
        top: 850px;
    }
}

.SPText {
    height: 30px;
    line-height: 30px;
    border: 1px solid black;
    color: #616161;  
    padding: 10px; 
    border-radius: 5px;
}

.FreeSPButton {
    height: 20px;
    width: 20px; 
    margin-left: 7px;
    color: white;
    background: green;
    border: 0; 
    border-radius: 50%; 
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.Plus:before,
.Plus:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
}
.Plus:before {
    width: 2px;
    margin: 5px auto;
}
.Plus:after {
    height: 2px;
    margin: auto 5px;
}

.FreeSPButtonDisabled {
    pointer-events: none;
    background-color: #a2d2a2;
}

.FreeSPButton:hover,
.FreeSPButton:active {
    background-color: #4fba4f; 
    cursor: pointer; 
    outline: 0; 
}

.FreeSPError {
    color: red; 
}
