.SpinInfo {
    background-color: white;
    color: #616161;
    padding: 16px 16px 16px 16px; 
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #ccc;
    width: 100%;  
    height: 275px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}
