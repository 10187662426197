.Authentication {
    margin: 100px auto;
    width: 70%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc; 
    border: 1px solid #eee;
    background-color: white; 
    padding: 20px;
}

.Shake {
    animation: shake 0.5s; 
}

.Error {
    color: red; 
    margin-bottom: 15px;
}

@media (min-width: 800px) {
    .Authentication {
        width: 500px; 
    }
}

@keyframes shake {
    0% {transform: translate(30px); }
    20% {transform: translate(-30px); }
    40% {transform: translate(15px); }
    60% {transform: translate(-15px); }
    80% {transform: translate(8px); }
    100% {transform: translate(0px); }
}
