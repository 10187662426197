.Profile {
    width: 1000px; 
    margin: 100px auto;  
    margin-bottom: 0; 
}

.Header {
    font-size: 48px; 
    font-weight: bold; 
    background-color: white;
    padding: 16px 16px 16px 16px; 
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #ccc;
    margin-bottom: 16px;  
}

.Body {
    display: flex; 
}

.FetchError {
    text-align: center; 
    font-weight: bold; 
    color: #616161; 
    margin-top: 100px; 
    animation: sizeChange 1s ease-in-out infinite; 
}

.LoadingSpinner {
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%); 
}

@keyframes sizeChange {
    0% {font-size: 40px}
    50% {font-size: 42px}
    100% {font-size: 40px}
}
