.Right {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Seller {
    font-weight: bold;
    font-size: 20px; 
}

.ListTime {
    font-size: 10px;
    font-style: italic; 
    margin-top: 16px; 
    color: #616161; 
}

.Error {
    color: red;
    text-align: center;
    position: relative;
    top: 23px;
}
